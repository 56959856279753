.MuiFilledInput-underline::before,
.MuiFilledInput-underline::after {
  border-bottom: 0 !important;
}

.MuiFilledInput-root {
  background: none !important;
  transition: none !important;
}

.MuiFilledInput-root:hover {
  background: none !important;
}

.Mui-focused {
  background: none !important;
}
