.comanda-table {
  border-collapse: collapse;
}

.comanda-table table,
td,
th {
  border: 0.5px solid rgba(0, 0, 0, 0.22);
}

.comanda-table tr td {
  /* border: 0.5px solid rgba(0, 0, 0, 0.22); */
  padding: 5px 2px;
}

.comanda-table tr td p {
  margin: 0;
  padding: 0;
}

.comanda-table tr td p.dir {
  margin-bottom: 4px;
}

.comanda-table tr:nth-child(odd) {
  background: rgba(53, 164, 254, 0.2);
}

.datos-cliente {
  font-family: "Montserrat";
  margin-top: 4px;
}

.datos-cliente h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}

.datos-cliente table {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
