.banner-container {
  margin-top: 4px;
  width: 100%;
}

.banner-container .banner-title {
  display: flex;
}

.banner-container .banner-title .icon-button {
  margin-right: 4px;
  padding: 6px;
}

.banner-container .banner-title span.title {
  font-size: 1em;
  align-self: center;
  /* font-weight: 600; */
  font-weight: 600;
}

.banner-container .preview-box {
  margin-top: 8px;
  height: 100px;
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
}
