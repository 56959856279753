.MuiInputBase-inputMultiline {
  /* background-color: red !important; */
  /* padding: 8.5px 4px !important; */
}

.phone-input input {
  margin-top: 16px;
  color: #515c6f;
  border: 1px solid #ced4da;
  padding: 10px 12px;
  font-size: 12px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  background-color: #fff;
}
