:root {
  --primary-color: #009be5;
  --selected-color: #4fc3f7;
  --secondary-color: #ef9700;
  --white-grey: rgba(255, 255, 255, 0.7);
  --grey-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
}

#admin-body {
  /* overflow-y: hidden !important; */
  /* min-width: 0 !important;
  min-height: 0 !important; */
  /* justify-content: flex-start !important; */
  font-family: Montserrat;
}

#admin-body input {
  font-family: Montserrat;
}

.container {
  display: flex;
  flex-direction: row;
  /* width: 100vw; */
  /* min-height: 100vw; */
  background: #eaeff1;
}

/* Login */

.container.login {
  height: 100vh;
  background: var(--primary-color);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container.login img {
  width: 12vw;
  height: auto;
}

.container.login .login-box {
  margin-top: 8px;
  width: 25vw;
  min-height: 220px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  box-shadow: var(--grey-shadow);
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}

.container.login .login-box .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.container.login .login-box .input-group .label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}

.container.login .login-box .input-group input {
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 6px;
  width: 80%;
  margin-top: 10px;
}

.container.login .login-box .forgot-pass {
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  color: var(--secondary-color);
  cursor: pointer;
}

.container.login .login-box .button-container {
  margin-top: 12px;
  width: 85%;
}

.container.login .login-box .button-container .button {
  background: var(--secondary-color);
  height: 35px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.container.login .login-box .button-container .button:hover {
  opacity: 0.95;
}

.container.login .login-box .error-box {
  font-weight: 600;
  font-size: 12px;
  margin-top: 12px;
  color: red;
}

/* Navbar */

.navbar {
  width: 300px;
  min-height: 100vh;
  background: #18202c;
}

.navbar .header {
  padding: 16px;
  height: 30px;
  background: rgba(255, 255, 255, 0.08);
  border-bottom: 2px solid #404854;
}

.navbar .header span {
  font-size: 24px;
  color: #ffffff;
}

.navbar .aliado-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #404854;
  background: rgba(255, 255, 255, 0.03);
}

.navbar .aliado-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.navbar .aliado-header .image {
  background: white;
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

.navbar .aliado-header .aliado-nombre {
  font-size: 16px;
  color: var(--white-grey);
  margin-left: 8px;
  font-weight: 500;
}

.navbar .section {
  padding: 16px;
  border-bottom: 1px solid #404854;
}

.navbar .section span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.navbar .section .item-container {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.navbar .section .item-container .item {
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.navbar .section .item-container .item svg {
  color: rgba(255, 255, 255, 0.7);
  margin-right: 5px;
}

.navbar .section .item-container .item .icon {
  height: 20px;
  width: 20px;
  background: #404854;
  margin-right: 8px;
  border-radius: 5px;
}

.navbar .section .item-container .item span {
  font-size: 13px;
  color: var(--white-grey);
}

.navbar .section .item-container .item span:hover {
  color: var(--primary-color);
}

.navbar .section .item-container .item.selected span {
  color: var(--primary-color);
}

.navbar .section .item-container .item.selected span:hover {
  color: var(--white-grey);
}

/* Content */

.content {
  /* width: calc(100% - 255px); */
  width: 100%;
  min-height: 100vh;
}

.content .header {
  display: flex;
  height: 62px;
  background: var(--primary-color);
  align-items: center;
  justify-content: flex-end;
  padding: 0px 10px;
}

.content .header .salir {
  color: var(--white-grey);
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.content .header .salir:hover {
  color: var(--selected-color);
  text-decoration: underline;
}

.content .body {
  padding: 20px;
  display: flex;
  justify-content: center;
}

/* Tienda */

.tienda-container {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.tienda-container .tienda-header {
  height: 180px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  position: relative;
}

.tienda-container .tienda-header .header-title {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.tienda-container .tienda-header .header-title span {
  /* margin: 4px 0px; */
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.tienda-container .tienda-header .header-title span a,
.tienda-container .tienda-header .header-title span a:link,
.tienda-container .tienda-header .header-title span a:active,
.tienda-container .tienda-header .header-title span a:visited,
.tienda-container .tienda-header .header-title span a:hover {
  color: white;
}

.tienda-container .tienda-header .header-title span.categories {
  margin: 5px 0px;
}

.tienda-container .tienda-header .header-title span.title {
  font-size: 28px;
  padding: 0;
  margin: 0;
  font-weight: bold;
  /* margin-bottom: 8px; */
}

.tienda-container .tienda-header .header-title .title-container {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.tienda-container
  .tienda-header
  .header-title
  .title-container
  .input-container {
  display: flex;
  align-items: center;
  border-bottom: 2px solid white;
}

.tienda-container
  .tienda-header
  .header-title
  .title-container
  .input-container
  input {
  border: none;
  background: transparent;
  color: white;
  font-size: 28px;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-family: Montserrat;
}

.tienda-container
  .tienda-header
  .header-title
  .title-container
  .input-container
  input:focus {
  outline: none;
}

.tienda-container
  .tienda-header
  .header-title
  .title-container
  .icon-container {
  margin: 0;
  padding: 0;
  margin-top: -2px;
  margin-left: 6px;
}

.tienda-container
  .tienda-header
  .header-title
  .title-container
  .icon-container
  .icon {
  cursor: pointer;
  /* margin-bottom: 2px; */
}

.tienda-container .tienda-header .edit {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
}

.tienda-container .tienda-body {
  /* width: 100%; */
  min-height: 200px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: var(--grey-shadow);
  background: white;
  padding: 16px;
}

.tienda-container .tienda-body .colores-container {
  display: flex;
  flex-direction: column;
}

.double-input-container {
  display: flex;
  justify-content: space-between;
}

.tienda-container .tienda-body .colores-container.numero {
  margin: 20px 0px;
  /* width: 50%; */
}
.tienda-container .tienda-body .colores-container.numero .input-body {
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 50%;
}

.tienda-container .tienda-body .colores-container.numero.coords .input-body {
  margin-top: 12px;
  width: 100%;
}

.tienda-container .tienda-body .colores-container.numero.coords .input-body {
  width: 45%;
}

.tienda-container
  .tienda-body
  .colores-container.numero.coords
  .input-body:last-child {
  /* justify-content: flex-end; */
}

.tienda-container .tienda-body .colores-container.numero .input-body span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
}

.tienda-container .tienda-body .colores-container.numero .colores-title small {
  font-size: 10px;
  color: grey;
  font-style: italic;
}

.tienda-container .tienda-body .colores-container.numero input {
  margin: 5px 0px;
}

.tienda-container .tienda-body .colores-container .colores-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tienda-container .tienda-body .colores-container .colores-title {
  font-weight: 600;
}

.tienda-container .tienda-body .colores-container .color-input {
  margin-top: 10px;
  width: 45%;
}

.tienda-container .tienda-body .footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
}

.tienda-container .tienda-body .footer .button-group {
  display: flex;
  justify-content: space-between;
}

.tienda-container .tienda-body .footer .button-group .button {
  cursor: pointer;
  min-width: 80px;
  max-width: 150px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.85);
  margin: 0px 10px;
  font-size: 14px;
  font-weight: 600;
}

.tienda-container .tienda-body .footer .button-group .button:hover {
  background: rgba(0, 0, 0, 0.6);
}

.tienda-container .tienda-body .footer .button-group .button.guardar {
  background: var(--primary-color);
}

.tienda-container .tienda-body .footer .button-group .button.guardar:hover {
  background: var(--selected-color);
}

.productos-container {
  background: white;
  border-radius: 5px;
  min-height: 500px;
  padding: 20px;
  width: 85%;
}

.productos-container .productos-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productos-container .productos-header h2 {
  padding: 0;
  margin: 0;
}

.productos-container .productos-header .right {
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.productos-container .productos-header .right .select-productos {
  width: 70%;
  border: 1px solid var(--primary-color);
  padding: 5px;
  border-radius: 5px;
  margin-left: 12px;
}

.productos-container .productos-header .right .reorder-button {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  /* width: 140px; */
  cursor: pointer;
}

.productos-container .productos-header .right .reorder-button span {
  font-size: 8px;
  font-weight: 600;
  color: white;
}

.productos-container .productos-body {
  margin-top: 12px;
}

.tag-container {
  display: flex;
  border: 2px solid var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  /* width: 100%; */
  /* max-width: 40vw; */
  /* height: 100px; */
  list-style: none;
  flex-wrap: wrap;
  margin: 0px;
}

.tag-container.empty {
  border: none;
  padding: 5px 0px;
  /* margin: 0px; */
}

.tag-container .tag {
  background: var(--primary-color);
  border-radius: 5px;
  padding: 0px 2px;
  margin-right: 5px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 5px;
  cursor: pointer;
}

.tag-container .tag:hover {
  background: var(--selected-color);
}

.tag-container.empty .tag {
  /* background: red;
  margin: 0px; */
}

.tag-container .tag span {
  padding: 0px 5px;
  user-select: none;
}

.tag-container .tag.add {
  background: none;
  color: var(--primary-color);
}

.tag-container .tag.add:hover {
  color: var(--selected-color);
}

.tag-container .tag input {
  background: transparent;
  caret-color: white;
  color: white;
}

.tag-container .tag input:focus {
  outline: none;
}

.tag-container .tag input.editing:hover,
.tag-container .tag.editing:hover > input {
  background: none;
  border: none;
}

.tag-container .tag.editing:hover {
  background: var(--primary-color);
}
