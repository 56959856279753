.color-picker {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.color-picker span {
  font-size: 14px;
  font-weight: 500;
}
