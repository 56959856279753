.content-panel {
  width: 200px;
  min-height: 100px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 20px;
  padding: 2px 5px;
  border-radius: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content-panel .columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.content-panel .columns .column {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.content-panel button {
  margin: 10px 0px;
  height: 50px;
}
