.tabla-productos {
  padding: 0px 10px;
  /* margin-top: 10px; */
}
.tabla-productos table {
  /* height: 300px; */
  overflow-y: scroll;
}

.tabla-productos .tabla-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}

.tabla-productos .tabla-title h2 {
  margin: 0;
  padding: 0;
}

.tabla-productos .tabla-title .right-container {
  display: flex;
  align-items: flex-end;
}

.agregar-container {
  background: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.ingrediente-container .tabla-productos .agregar-container {
  margin: 0px;
  padding: 2px;
  margin-left: 6px;
}

.agregar-container:hover {
  background: var(--selected-color);
}

.agregar-container span {
  margin-right: 6px;
}

.tabla-productos .tabla-title .text-input {
  display: flex;
  justify-content: space-between;
  border: none;
  padding: 0px 5px;
  width: 250px;
  border-bottom: 2px solid var(--primary-color);
}

.tabla-productos .tabla-title .text-input input {
  padding: 0px;
  border: none;
  width: 80%;
  font-family: revert !important;
}

.tabla-productos .tabla-title .text-input input:focus {
  outline: none;
}

.tabla-productos table,
.tabla-productos table td,
.tabla-productos table th {
  border: none !important;
}

.tabla-productos table {
  width: 100%;
}

.tabla-productos table th {
  font-size: 14px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.tabla-productos table tbody {
  overflow-y: scroll;
}

.tabla-productos table td {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  padding: 10px;
  background: rgba(0, 0, 0, 0.02);
}

.modal-container {
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-container {
  max-width: 330px;
}

.modal-container.edit .info {
  font-size: 12px;
  width: 80%;
  text-align: center;
}

.modal-container h2 {
  padding: 0;
  margin: 0;
  padding: 15px;
  text-align: center;
}

.modal-container.imagen {
  width: 400px;
}

.modal-container.imagen .imagen-container {
  width: 80%;
  padding-top: 56.25%;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.modal-container.imagen p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 12px;
}

.modal-container .add-image {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: black;
}

.modal-container.edit {
  padding: 25px;
}

.modal-container.edit .input-group {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 5px 0px;
  width: 100%;
}

.modal-container.edit .input-group.precio {
  width: 30%;
}

.modal-container.edit .input-group span {
  margin: 5px 0px;
  font-size: 12px;
  font-weight: 600;
  /* text-align: center; */
}

.modal-container.edit .input-group .categorias-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
}

.modal-container.edit .input-group .categorias-container .texto {
  margin: 0;
  padding: 0;
  align-self: flex-start;
}

.modal-container.edit .input-group input,
.modal-container.edit .input-group textarea {
  border: 2px solid var(--primary-color);
  padding: 5px 10px;
  border-radius: 5px;
  /* width: 100%; */
  font-weight: 500;
  font-size: 12px;
  resize: none;
  font-family: Montserrat;
}

.modal-container.edit .input-group textarea {
}

.modal-container.edit .button-group {
  margin-top: 20px;
}

.modal-container.edit .button-group button {
  border: none;
  padding: 10px;
  margin: 0px 5px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
}

.modal-container.edit .button-group .descartar {
  background: #bf211e;
}

.modal-container.edit .button-group .guardar {
  background: #06a77d;
}

.modal-container.edit.ingredientes {
  min-width: 70vw;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-container.edit.ingredientes h2 {
  align-self: flex-start;
  margin: 0;
  padding: 0;
}

.modal-container.edit.ingredientes .ingredientes-container {
  margin-top: 20px;
  width: 100%;
}

.modal-container.edit.ingredientes
  .ingredientes-container
  .ingrediente-container {
  /* background-color: yellow; */
  /* margin-bottom: 18px; */
}

.modal-container.edit.ingredientes
  .ingredientes-container
  .ingrediente-container:last-child {
  margin-top: 60px;
}

.modal-container.edit.ingredientes
  .ingredientes-container
  .ingrediente-container
  .ingrediente-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-container.edit.ingredientes
  .ingredientes-container
  .ingrediente-container
  .ingrediente-title
  h2 {
  font-size: 20px;
  text-transform: capitalize;
  align-self: center;
}

.modal-container.edit.ingredientes
  .ingredientes-container
  .ingrediente-container
  .ingrediente-body {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.ingrediente-body .tabla-title h2 {
  text-transform: capitalize;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

label + .MuiInput-formControl {
  /* margin-top: 28px; */
}

.modal-container.edit.ingredientes .info {
  margin: 30px 0px;
  width: 100%;
}
