.categorias-modal {
  width: 30vw;
  height: 85vh;
  background: white;
  padding: 20px 15px;
  border-radius: 5px;
  overflow-y: auto;
}

.categorias-modal h2 {
  padding: 0;
  margin: 0;
}

.categorias-modal .sub-text {
  font-size: 12px;
}

.categorias-modal .categorias {
  list-style: none;
  margin: 0;
  padding: 0;
}

.categorias-modal .categorias li {
  background: var(--primary-color);
  border-radius: 5px;
  margin: 5px 0px;
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.categorias-modal .categorias li span {
  margin-left: 8px;
}
