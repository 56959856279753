.admin-container {
  --primary-color: #4fbbf9;
  --secondary-color: #ef9700;
  --warning: #db162f;
  --dark-blue: #18202c;
  --dark-border: #243142;
  --shadow-one: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  background: var(--primary-color);
}

/* General */

.admin-container .button {
  background: var(--warning);
  border-radius: 5px;
  padding: 2px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.admin-container h2,
.admin-container h3,
.admin-container h4 {
  margin: 0;
  padding: 0;
}

.admin-container .app-margin {
  min-height: 70%;
  width: 90%;
  padding-top: 3%;
}

.admin-container .app-margin .logo {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 4px 0px;
}

.admin-container .app-margin img {
  width: 110px;
  height: auto;
}

.admin-container .app-margin .app-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: var(--shadow-one);
  border-radius: 5px;
  margin-bottom: 48px;
}

/* Menu Bar */

.admin-container .app-margin .app-container .menu-bar {
  background: var(--dark-blue);
  width: 240px;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: column;
}

.admin-container .app-margin .app-container .menu-bar .menu-item {
  padding: 18px 14px;
  border-bottom: 1px solid var(--dark-border);
  font-size: 14px;
  color: #f8f8f8;
  cursor: pointer;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item:not(.menu-first):not(.active):hover {
  background: #161d27;
}

.admin-container .app-margin .app-container .menu-bar .menu-item.active {
  background: var(--primary-color);
}

.admin-container .app-margin .app-container .menu-bar .menu-item.expandable {
  display: flex;
  flex-direction: column;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .father-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  svg {
  font-size: 22px;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container {
  margin-top: 8px;
  padding: 8px;
  cursor: pointer;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container
  .sub-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container
  .sub-item.active {
  color: var(--secondary-color);
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container
  .sub-item:hover {
  color: var(--secondary-color);
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container
  .sub-item:last-child {
  margin-bottom: 0px;
}

.admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item.expandable
  .sub-container
  .sub-item
  span {
  margin-left: 8px;
  font-size: 12px;
}

/* .admin-container
  .app-margin
  .app-container
  .menu-bar
  .menu-item:not(.menu-first):not(.active):hover {
  background: var(--dark-border);
  border-bottom: 1px solid #1d2735;
} */

.admin-container .app-margin .app-container .menu-bar .menu-item.menu-first {
  border-top-left-radius: 5px;
}

.admin-container .app-margin .app-container .menu-bar .menu-item.menu-first {
  font-size: 18px;
}

.admin-container .app-margin .app-container .app-content {
  padding: 24px;
  background: #f8f8f8;
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-x: scroll;
}

.admin-container .app-margin .app-container .app-content .analytics-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
}

.admin-container .app-margin .app-container .app-content .generar-plantillas {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.admin-container
  .app-margin
  .app-container
  .app-content
  .generar-plantillas
  .button-container {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

/* Analytic Component */

.analytic-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.analytic-item span {
  font-size: 16px;
  font-weight: 600;
}

.analytic-item .analytic-box {
  min-width: 120px;
  height: 60px;
  background: var(--secondary-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 0px 14px;
}

.analytic-item .analytic-box span {
  font-size: 24px;
  font-weight: bold;
  color: white;
  letter-spacing: 2px;
}

/* Button With Label */

.button-with-label {
  display: flex;
  align-items: center;
}

.button-with-label:not(:first-child) {
  margin-left: 24px;
}

.button-with-label span {
  font-size: 14px;
  font-weight: 700;
  margin-right: 12px;
}

.button-with-label .button {
  background: var(--primary-color);
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.button-with-label .button:hover {
  opacity: 0.8;
}

/* Custom Selector */

.custom-selector {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  width: 350px;
}

.custom-selector .title-container {
  display: flex;
  align-items: center;
}

.custom-selector .title-container span {
  font-weight: 600;
  font-size: 18px;
}

.custom-selector .title-container svg {
  font-size: 28px;
}

.custom-selector .weeks-container {
  position: absolute;
  width: 100%;
  background: var(--dark-blue);
  min-height: 100px;
  top: 30px;
  border-radius: 5px;
  box-shadow: var(--shadow-one);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  max-height: 15vh;
  overflow-y: scroll;
  z-index: 99;
}

.custom-selector .weeks-container .week-item {
  padding: 10px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--dark-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-selector .weeks-container .week-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-selector .weeks-container .week-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.custom-selector .weeks-container .week-item svg {
  font-size: 16px;
}

.custom-selector .weeks-container .week-item:hover {
  background: var(--dark-border);
}

.custom-selector .weeks-container .week-item:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-selector .weeks-container .week-item:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Crear Rider */

.crear-rider {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crear-rider .avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.crear-rider h4 {
  margin-top: 14px;
}

.crear-rider .avatar-container .avatar {
  height: 100px;
  width: 100px;
}

.crear-rider .basic-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.crear-rider .basic-info-container h4 {
  margin: 0px;
}

.crear-rider .basic-info-container .basic-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 12px;
  height: 100%;
}

.crear-rider .rider-info-primary {
  display: flex;
  margin-top: 12px;
  margin-bottom: 30px;
}

.crear-rider .form-item {
  margin-bottom: 6px;
  margin-right: 10px;
}

.crear-rider .datos-acceso {
  margin-top: 12px;
}

.crear-rider .datos-acceso .form-item:first-child {
  margin-left: 0px;
}

.crear-rider .section {
  margin-top: 12px;
}

.crear-rider .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.crear-rider .footer .button-crear {
  width: 300px;
}
